import 'normalize.css';
import Typewriter from 'typewriter-effect/dist/core';
import * as $ from 'jquery';
import slick from 'slick-carousel';
import "slick-carousel/slick/slick.css";

import '../styles/index.scss';

document.addEventListener("DOMContentLoaded",function(){
  let typewriter = new Typewriter('[data-typewriter]', {
    delay: 40
  });

  typewriter.changeDeleteSpeed(10)
    .pauseFor(1000)
    .typeString('Je suis Thomas Lecoeur.')
    //.pauseFor(500)
    //.changeDelay(30)
    //.typeString('<br/><small>“Lecoeur” means the heart in french</small>')
    //.deleteChars(36)
    .pauseFor(2000)
    .deleteAll()
    .pauseFor(300)
    .changeDelay(40)
    .typeString('Je suis Développeur<br/>Front-End Freelance.')
    .pauseFor(2000)
    .deleteAll()
    .pauseFor(300)
    .typeString('J\'habite à Annecy.')
    .pauseFor(2000)
    .deleteAll()
    .pauseFor(300)
    .typeString('Je suis Thomas Lecoeur.')
    .start();

  setTimeout(function() {
    $('[data-header]').css('height', $('[data-header]').height());
  }, 1000);

  let $projectSlider = $('[data-project-slider]');

  $projectSlider.slick({
    slidesToShow: 1,
    infinite: false,
    arrows: false
  });

  $projectSlider.on('click', '.slick-slide:not(.slick-current)', function(e){
    e.preventDefault();
    var slideIndex = $(this).data('slick-index');
    console.log(slideIndex);
    $projectSlider.slick('slickGoTo', slideIndex);
  });

  let $gifContainer = $('[data-gif-container]');

  $('[data-gif]').click(function() {
    $gifContainer.css('background-image', 'url("' + $(this).data('gif') + '")');
    $gifContainer.addClass('enabled');
  });

  $gifContainer.click(function() {
    $gifContainer.removeClass('enabled');
    $gifContainer.css('background-image', '');
  });
});
